<template>
  <div>
    <div v-if="loadingProfileData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('PROFILE')})  | capitalize}}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize" class="mb-2">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- name input -->
          <b-form-group
              id="name-form-group"
              :label="$t('PROFILE_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')"
          >
            <b-form-input id="name-input" v-model="$v.form.name.$model" :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('PROFILE_NAME_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize}}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize}}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>

    <permission-selector :loading="loadingProfilePermissions" v-model="form.permissions" :initial-values="permissions"></permission-selector>
  </div>
</template>

<script>
import {Form} from "@/mixins";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import Profiles from "@/users/profiles";
import Permissions from "@/users/permissions";
import PermissionSelector from "@/users/permissions/PermissionSelector";

export default {
  name: "ProfileForm",
  components: {PermissionSelector},
  mixins: [Form],
  methods: {

    getData() {
      this.loadingProfileData = true;
      Profiles.findById(this.id)
          .then(resp => {
            this.form.name = resp.data.name;
            this.originals.name = resp.data.name;
          })
          .catch(e => {
            console.error(e);
            this.sendError('ERROR_LOADING_PROFILE_DATA', e);
          })
          .finally(() => {
            this.loadingProfileData = false;
          });

      this.loadingProfilePermissions = true;
      Permissions.findByProfileId(this.id)
          .then(resp => {
            this.permissions = resp.data._embedded.permissions;
            this.form.permissions = this.permissions.map(x => x._links.self.href);
          })
          .catch(e => {
            console.error(e);
            this.sendError('ERROR_LOADING_PROFILE_PERMISSIONS', e);
          })
          .finally(() => {
            this.loadingProfilePermissions = false;
          });
    }
  },
  data() {
    return {
      originals: {
        name: undefined
      },
      form: {
        name: null,
        permissions: [],
      },
      permissions: [],
      loadingProfileData: false,
      loadingProfilePermissions: false,
      editPage: 'PROFILE_EDIT_TITLE'
    }
  },
  computed: {
    controller() {
      return Profiles;
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(32),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.name && this.originals.name === value) return true;

          // simulate async call, fail for all logins with even length
          return Profiles.nameExists(value);
        }
      },
    }
  }
}
</script>

<style scoped>

</style>
